const AgentMenuList = [
  {
    name: "Dashboard",
    path: "dashboard",
    icon: "tachometer-alt"
  }, {
    name: "Manual Calling",
    path: "calling",
    icon: "mobile"
  },
];

export default AgentMenuList;
