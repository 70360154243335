import React from "react";

export default function DashboardAdmin() {
  
  return (
    <>
      {/* <DashboardCard /> */}
      <h1>Dashboard</h1>

    </>
  );
}
